const getBrowserName = () => {
    const agent = window.navigator.userAgent;
    if (agent.indexOf("Firefox") > -1) {
      return "Firefox";
    } else if (agent.indexOf("SamsungBrowser") > -1) {
      return "Samsung Internet";
    } else if (agent.indexOf("Opera") > -1 || agent.indexOf("OPR") > -1) {
      return "Opera";
    } else if (agent.indexOf("Trident") > -1) {
      return "Internet Explorer";
    } else if (agent.indexOf("Edge") > -1) {
      return "Edge";
    } else if (agent.indexOf("Chrome") > -1) {
      return "Chrome";
    } else if (agent.indexOf("Safari") > -1) {
      return "Safari";
    }
    return "Unrecognized";
  };

export { getBrowserName };